import { createReducer, on } from '@ngrx/store';
import { FaqOverviewSearchActions } from '../actions';
import { FaqOverviewState } from './faq-overview.reducer';

export const FAQ_OVERVIEW_SEARCH_FEATURE_KEY = 'faqOverviewSearch';

export interface FaqOverviewSearchState extends FaqOverviewState {}

export const initialStateOverviewSearch: FaqOverviewSearchState = {
  isUserLoggedIn: undefined,
  response: undefined,
  loading: false,
  error: undefined,
};

export const faqOverviewSearchReducer = createReducer(
  initialStateOverviewSearch,

  on(FaqOverviewSearchActions.loadFaqOverviewSearch, (state, action) => ({
    ...state,
    isUserLoggedIn: action.isUserLoggedIn,
    loading: true,
    response: undefined,
  })),
  on(
    FaqOverviewSearchActions.loadFaqOverviewSearchSuccess,
    (state, action) => ({
      ...state,
      loading: false,
      response: action.response,
      isUserLoggedIn: undefined,
    })
  ),
  on(
    FaqOverviewSearchActions.loadFaqOverviewSearchFailure,
    (state, action) => ({
      ...state,
      loading: false,
      error: action.errorResponse,
    })
  ),
  on(FaqOverviewSearchActions.resetFaqOverviewSearch, () => ({
    ...initialStateOverviewSearch,
  }))
);
