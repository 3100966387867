<div class="mini-cart-item-container">
    <div class="article-name">
        {{item.product.name}}
    </div>

    <div class="article-details">
        <div *ngIf="item.product.manufacturerAID" class="manufacturerAID">
            {{ item.product.manufacturerAID }}
            <span *ngIf="item.product.manufacturer; else displayManufacturerId">&nbsp;/&nbsp;{{
                item.product.manufacturer }}</span>
            <ng-template #displayManufacturerId>
                <span *ngIf="item.product.manufacturerId">&nbsp;/&nbsp;{{ item.product.manufacturerId
                    }}</span>
            </ng-template>
        </div>

        <div *ngIf="item.basePrice" class="price">
            {{ item.basePrice?.formattedValue }}
        </div>
    </div>

    <div class="mini-cart-item-actions">
        <app-efa-item-counter [control]="quantityControl" [readonly]="!item.updateable || readonly || item.parent"
            [allowZero]="true">
        </app-efa-item-counter>

        <div *ngIf="!item.parent && item.updateable" class="actions-delete">
            <cx-icon [type]="iconType.CLEAR" (click)="removeItem()" class="u-pointer"></cx-icon>
        </div>
    </div>
</div>
