<div class="row">
  <div class="col-2">
    <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
       [queryParams]="{ source: pdpCallSource }"
       class="cx-product-image-container"
       tabindex="-1">
      <cx-media class="cx-product-image"
                [container]="product.images?.PRIMARY"
                format="thumbnail"
                [alt]="product.summary"></cx-media>
    </a>
  </div>
  <div class="col-5 col-sm-6 col-md-5 pl-0">
    <div class="row">
      <div class="col-12">
        <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        [queryParams]="{ source: pdpCallSource }"
        class="cx-product-name"
        [innerHtml]="product.nameHtml"></a>
      </div>
      <div class="col-lg-3 col-md-4 col-5">
        <a [routerLink]="{ cxRoute: 'product', params: product } | cxUrl"
        [queryParams]="{ source: pdpCallSource }"
        class="manufacturerAID"
        [innerHtml]="product.manufacturerAID"></a>
      </div>
      <div class="col-lg-9 col-md-8 col-7">
        <app-product-badge *ngIf="product.productCampaign" [content]="product.productCampaign?.text"
        [style.backgroundColor]="product.productCampaign?.backgroundColor"
        [style.color]="product.productCampaign?.textColor"
        class="list-item-product-badge"></app-product-badge>
      </div>
    </div>

  </div>

  <div class="col-5 col-sm-4 col-md-5">

    <ng-template [cxOutlet]="ProductListOutlets.ITEM_ACTIONS">
      <div class="action-box d-flex">
        <app-efa-add-to-cart
                          [isCalledFromSERP]="true"
                          [product]="product"
                          [showQuantityHint]="false"
        >

        </app-efa-add-to-cart>
        <app-efa-cart-item-add-to-wish-list [product]="product"
                                            [orderInfoRefId]="0"
                                            [IsAddedFromSearchPage]="true">
        </app-efa-cart-item-add-to-wish-list>
      </div>
    </ng-template>
  </div>

</div>
