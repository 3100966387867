import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DEPOSIT_ARTICLE_NUMBER } from '@shared/models/shared.model';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';
import { EfaGlobalMessageService } from '@shared/services/efa-global-message.service';
import { ActiveCartFacade, Cart, DeliveryMode, OrderEntry } from '@spartacus/cart/base/root';
import { Address, GlobalMessageType } from '@spartacus/core';
import { LAUNCH_CALLER, LaunchDialogService } from '@spartacus/storefront';
import { Observable, combineLatest, filter, first, map } from 'rxjs';
import { OrderType } from '../../cart-administration/model/cart-administration.model';
import { UpdateCartArg } from '../../cart-administration/model/update-cart.model';
import { B2BUserAccountService } from '../../cart-administration/services/b2-b-user-account.service';
import { CartParametersService } from '../../cart-administration/services/cart-parameters.service';
import { UpdateCartService } from '../../cart-administration/services/update-cart.service';
import { PermissionCheckService } from '../../user-profiles/service/permission-check.service';
import { OrderInfoFieldsFormsValidationService } from './order-info-fields-forms-validation.service';

@Injectable({
  providedIn: 'root'
})
export class EfaValidateCartGuard  {
  private ot: OrderType;
  private dm: DeliveryMode;
  private rd: NgbDateStruct;
  private ra: Address;
  private ac: Cart;
  private dc: boolean;
  private startCheckout = false;

  constructor(protected b2bUserAccountService: B2BUserAccountService,
              protected cartParametersService: CartParametersService,
              protected updateCartService: UpdateCartService,
              protected messageService: EfaGlobalMessageService,
              protected orderInfoFieldsFormsValidationService: OrderInfoFieldsFormsValidationService,
              protected activeCartService: ActiveCartFacade,
              protected cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
              protected permissionCheckService: PermissionCheckService,
              private launchDialogService: LaunchDialogService) {

    combineLatest([this.cartParametersService.getCurrentOrderType(),
      this.cartParametersService.getCurrentShippingType(),
      this.cartParametersService.getCurrentDeliveryAddress(),
      this.cartParametersService.getRequestDeliveryDate(),
      this.permissionCheckService.displayCustomerPrices(),
      this.activeCartService.getActive()])
      .subscribe(([ot, dm, ra, rd, dc, ac]) => {
        this.ot = ot;
        this.dm = dm;
        this.ra = ra;
        this.rd = rd;
        this.dc = dc;
        this.ac = ac;
      });

    this.updateCartService.isUpdating().subscribe(next => {
      if (this.startCheckout && !next) {
        this.cartAndCheckoutLoadingService.setCartItemListLoading(false);
        this.startCheckout = false;
      }
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (!this.orderInfoFieldsFormsValidationService.getAllValid()) {
      this.orderInfoFieldsFormsValidationService.markAllAsTouched();
      this.messageService.add({key: 'checkoutOrder.globalMessage.mandatoryOrderInfoFieldsMissing'},
        GlobalMessageType.MSG_TYPE_ERROR);
      return false;
    }

    const orderEntries: OrderEntry[] = this.ac?.entries;
    if (orderEntries != null) {
      const exceedingLimitOrderEntry = this.getExceedingLimitOrderEntry(orderEntries);
      if (exceedingLimitOrderEntry != null) {
        return this.openCartLimitExceededModal();
      }
    }

    

    let ret = true;
    if (this.ot === undefined || this.dm === undefined || this.ra === undefined) {
      this.orderInfoFieldsFormsValidationService.markAllAsTouched();
      this.messageService.add({key: 'checkoutOrder.globalMessage.mandatoryHeaderFieldsMissing'},
        GlobalMessageType.MSG_TYPE_ERROR);
      ret = false;
    } else {
      if (this.rd === undefined) {

        const currentDate = new Date();
        this.rd = {
          day: currentDate.getDate(),
          month: currentDate.getMonth() + 1,
          year: currentDate.getFullYear()
        };
      }
      const arg: UpdateCartArg = {
        cartId: this.b2bUserAccountService.cartId,
        userId: this.b2bUserAccountService.userId,
        calculate: !this.ac.fromConfigurator,
        orderTypeId: this.ot.id,
        shippingTypeId: this.dm.code,
        addressId: this.ra.id,
        requestedDeliveryDate: this.rd.year + '-' + this.rd.month + '-' + this.rd.day,
        reloadCart: true
      };

      this.startCheckout = true;
      this.updateCartService.updateCart(arg);

    }
    return ret;
  }

  private getExceedingLimitOrderEntry(orderEntries: OrderEntry[]): OrderEntry {
    for (let i = 0; i < orderEntries.length; i++) {
      const orderEntry = orderEntries[i];
      if ((orderEntry.subEntry && orderEntry.product?.manufacturerAID === DEPOSIT_ARTICLE_NUMBER) || orderEntry.parent) {
        continue;
      }

      let totalPrice: number = this.getTotalPriceValueForOrderEntry(orderEntry);
      let totalQuantity: number = orderEntry?.quantity;
      
      orderEntry?.subEntries?.filter((subEntry: OrderEntry) => subEntry.product?.manufacturerAID !== DEPOSIT_ARTICLE_NUMBER).forEach((subEntry: OrderEntry) => {
        totalPrice = totalPrice + this.getTotalPriceValueForOrderEntry(subEntry);
        totalQuantity = totalQuantity + subEntry?.quantity;
      });

      let predecessorEntryIndex: number = i + 1;
      while (predecessorEntryIndex < orderEntries.length) {
        let predecessorOrderEntry: OrderEntry = orderEntries[predecessorEntryIndex];
        if (predecessorOrderEntry.parent) {
          totalPrice = totalPrice + this.getTotalPriceValueForOrderEntry(predecessorOrderEntry);
          totalQuantity = totalQuantity + predecessorOrderEntry.quantity;
        } else {
          break;
        }

        predecessorEntryIndex = predecessorEntryIndex + 1;
      }
      
      if (totalPrice > 9999 || totalQuantity > 999) {
        return orderEntry;
      }
    }
    return undefined;
  }

  private getTotalPriceValueForOrderEntry(orderEntry: OrderEntry): number {
    return this.dc ? orderEntry.customerTotalPrice?.value : orderEntry.totalPrice?.value;
  } 

  private openCartLimitExceededModal(): Observable<boolean> {
    this.launchDialogService.openDialogAndSubscribe(LAUNCH_CALLER.CART_LIMIT_EXCEEDED);  
    return this.launchDialogService.dialogClose.pipe(
      filter(Boolean),
      first(),
      map((dialogClose: string) => {
        if (dialogClose === 'confirmed') {
          return true;
        } else {
          return false;
        }
      })
    );
  }
}
