import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { FaqOverviewResponse } from '../../model/faq-items.model';

export const loadFaqOverview = createAction(
  '[FaqItems] Load FAQ Item Overview',
  props<{ isUserLoggedIn: boolean, searchTerm: string }>()
);

export const loadFaqOverviewSuccess = createAction(
  '[FaqItems] Load FAQ Item Overview Success',
  props<{ response: FaqOverviewResponse }>()
);

export const loadFaqOverviewFailure = createAction(
  '[FaqItems] Load FAQ Item Overview Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetFaqOverview = createAction(
  '[FaqItems] Reset Faq Item Overview'
);
