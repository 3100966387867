import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorType } from '@shared/models/ErrorTypes';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { FaqOverviewResponse } from '../../model/faq-items.model';
import { FaqOverviewConnector } from '../../occ/connectors/faq-overview.connector';
import { FaqOverviewSearchActions } from '../actions';

@Injectable()
export class FaqOverviewSearchEffect {
  loadFaqOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FaqOverviewSearchActions.loadFaqOverviewSearch),
      concatMap((action) =>
        this.connector
          .loadOverview(action.isUserLoggedIn, action.searchTerm)
          .pipe(
            map((faqOverviewResponse: FaqOverviewResponse) => {
              return FaqOverviewSearchActions.loadFaqOverviewSearchSuccess({
                response: faqOverviewResponse,
              });
            }),
            catchError((error: ErrorType) => {
              return of(
                FaqOverviewSearchActions.loadFaqOverviewSearchFailure({
                  errorResponse: error,
                })
              );
            })
          )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: FaqOverviewConnector
  ) {}
}
