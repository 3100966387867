import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'textHighlight' })
export class TextHighlightPipe implements PipeTransform {
  transform(text: string, match?: string): string {
    if (!match) {
      return text;
    }

    let final = '';
    const splitFlag = 'i';
    const matchFlag = 'gi';
    const searchPattern = new RegExp(match, splitFlag);
    const matchpattern = new RegExp(match, matchFlag);

    if (!match.startsWith('.')) {
      const separatedText = text.split(searchPattern);
      const separatedSearchedText = text.match(matchpattern);

      if (separatedSearchedText?.length > 0) {
        for (let i = 0; i < separatedText.length; i++) {
          if (i <= separatedSearchedText.length - 1) {
            final += `${separatedText[i]}<span class="u-highlight">${separatedSearchedText[i]}</span>`;
          } else {
            final += separatedText[i];
          }
        }

        return final;
      }
    }

    return text;
  }
}
