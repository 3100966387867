export const faqItems = {
  faqItems: {
    overviewSearchBox: {
      placeholder: 'Was können wir für Sie tun?',
      help: {
        noMatch: 'Wir konnten keine Ergebnisse finden',
      },
    },

    actions: {
      backToOverview: 'zurück zur Übersicht',
    },
  },
};
