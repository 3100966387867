<ng-container *ngIf="faqOverviewResponse$ | async as faqOverview">
    <div class="container">
        <div ngbAccordion *ngFor="let faqRubric of faqOverview?.faqRubrics">
            <div ngbAccordionItem [collapsed]="!(expandFaqRubrics$ | async)">
                <div ngbAccordionHeader>
                    <button ngbAccordionToggle class="btn btn-block">
                        <div class="u-font-bold">{{ faqRubric?.name }}</div>
                    </button>
                </div>
                <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                        <ng-template>
                            <div class="faq-item" *ngFor="let faqItem of faqRubric?.faqItems; let i=index"
                                [ngClass]="getFaqItemClass(i)">
                                <a [routerLink]="
                                    {
                                        cxRoute: getForwardRoute(), params: { faqItemCode: faqItem.code }
                                    } | cxUrl" [queryParams]="{ search: searchTerm$ | async }" class="btn-link">
                                    {{ faqItem?.title }}
                                    <cx-icon [type]="iconType.CARET_RIGHT"></cx-icon>
                                </a>
                            </div>
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>