import {Component, OnDestroy, OnInit} from '@angular/core';
import {Location} from '@angular/common'
import {AuthService, LanguageService} from '@spartacus/core';
import {combineLatest, Observable, Subscription} from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';
import {ICON_TYPE} from '../../model/faq-items-icon.model';
import {FaqItem} from '../../model/faq-items.model';
import {FaqItemService} from '../../services/faq-item.service';

@Component({
  selector: 'app-faq-details',
  templateUrl: './faq-details.component.html',
})
export class FaqDetailsComponent implements OnInit, OnDestroy {
  faqItem$: Observable<FaqItem>;
  searchTerm$: Observable<string>;
  readonly iconType = ICON_TYPE;

  private readonly subscription: Subscription = new Subscription();

  constructor(
    protected faqItemService: FaqItemService,
    protected location: Location,
    protected authService: AuthService,
    protected languageSevice: LanguageService
  ) {}

  ngOnInit(): void {
    this.faqItemService.resetFaqItem();
    this.searchTerm$ = this.faqItemService.getSearchTerm();
    this.subscription.add(
      combineLatest([
        this.faqItemService.getFaqItemCode(),
        this.authService.isUserLoggedIn(),
        this.languageSevice.getActive().pipe(distinctUntilChanged()),
      ]).subscribe(
        ([faqItemCode, isUserLoggedIn]: [string, boolean, string]) => {
          this.faqItemService.loadFaqItem(faqItemCode, isUserLoggedIn);
        }
      )
    );
    this.faqItem$ = this.faqItemService.getFaqItemResponse();
  }

  goBack(): void {
    this.location.back();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
