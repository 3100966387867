import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PDP_CALL_SOURCE, ProductCampaign } from '@shared/models/shared.model';
import { Product, RouterState, RoutingService } from '@spartacus/core';
import { CurrentProductService, ProductImagesComponent } from '@spartacus/storefront';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { pdpCallSourceToGoBackTranslation } from '../../model/product-details.model';

enum ICON_TYPE {
  ARROW_EXPAND_LEFT = 'ARROW_EXPAND_LEFT'
}
@Component({
  selector: 'app-efa-product-images',
  templateUrl: './efa-product-images.component.html',
})
export class EfaProductImagesComponent extends ProductImagesComponent implements OnInit,OnDestroy {
  productCampaign$: Observable<ProductCampaign> = this.product$.pipe((map((product: Product) => product.productCampaign)));
  goBackTranslationKey$: Subject<string> = new BehaviorSubject(undefined);
  iconTypes = ICON_TYPE;
  zoomedImageurl:string;
  productImageUrl:string;
  subscription: Subscription = new Subscription();
  isTabletMode = false;

  private readonly pdpCallSourceToGoBackTranslationRecord = pdpCallSourceToGoBackTranslation;
  private readonly defaultGoBackTranslationKey = 'eProductDetails.goBack.default';

  private closeWindowOnBack: boolean = true;

  constructor(
    protected currentProductService: CurrentProductService,
    protected location: Location,
    protected routing: RoutingService) {
    super(currentProductService)
  }

  goToPreviosPageOrClose() {
    if (this.closeWindowOnBack) {
      window.close();
    } else {
      this.location.back();
    }
  }

  ngOnInit(): void {
    let screenWidth = screen.width;

    if (screenWidth < 992) {
      this.isTabletMode = true
    }

    this.subscription.add(
      this.mainImage$.subscribe(mainImage  =>{
        this.zoomedImageurl = mainImage?.zoom?.url;
        this.productImageUrl = mainImage?.product?.url;
      })
    );

    this.subscription.add(
      this.routing.getRouterState().subscribe((route: RouterState) => {
        if (route.state.queryParams.source != null) {
          this.closeWindowOnBack = false;
          const source: PDP_CALL_SOURCE = route.state.queryParams.source;
          const translationKey: string = this.pdpCallSourceToGoBackTranslationRecord[source];

          if (translationKey != null) {
            this.goBackTranslationKey$.next(translationKey);
          } else {
            this.goBackTranslationKey$.next(this.defaultGoBackTranslationKey);
          }

        } else {
          this.goBackTranslationKey$.next(this.defaultGoBackTranslationKey);
        }
      })
    )
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
