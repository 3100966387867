import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as FaqItemReducers from './faq-item.reducer';
import * as FaqOverviewSearchReducers from './faq-overview-search.reducer';
import * as FaqOverviewReducers from './faq-overview.reducer';

export const FAQ_ITEMS_FEATURE_KEY = 'faqItems';

export interface FaqItemsState {
  [FaqOverviewReducers.FAQ_OVERVIEW_FEATURE_KEY]: FaqOverviewReducers.FaqOverviewState;
  [FaqItemReducers.FAQ_ITEM_FEATURE_KEY]: FaqItemReducers.FaqItemState;
  [FaqOverviewSearchReducers.FAQ_OVERVIEW_SEARCH_FEATURE_KEY]: FaqOverviewSearchReducers.FaqOverviewSearchState;
}

export const reducers: ActionReducerMap<FaqItemsState> = {
  [FaqOverviewReducers.FAQ_OVERVIEW_FEATURE_KEY]: FaqOverviewReducers.faqOverviewReducer,
  [FaqItemReducers.FAQ_ITEM_FEATURE_KEY]: FaqItemReducers.faqItemReducer,
  [FaqOverviewSearchReducers.FAQ_OVERVIEW_SEARCH_FEATURE_KEY]: FaqOverviewSearchReducers.faqOverviewSearchReducer,
};

export const getFaqItemsState = createFeatureSelector<FaqItemsState>(FAQ_ITEMS_FEATURE_KEY);
