<div role="search" class="searchbox-wrapper">
    <label class="searchbox">
        <input #faqOverviewSearchInput [placeholder]="'faqItems.overviewSearchBox.placeholder' | cxTranslate"
            autocomplete="off" aria-describedby="initialDescription" aria-controls="results" aria-autocomplete="both"
            [attr.aria-label]="'faqItems.overviewSearchBox.placeholder' | cxTranslate"
            (input)="change(faqOverviewSearchInput.value)" (keydown.enter)="
              search(faqOverviewSearchInput.value)" value="{{ chosenWord }}" />

        <div role="presentation" class="search-icon" [ngClass]="getSearchIconClass(faqOverviewSearchInput.value)"
            (click)="search(faqOverviewSearchInput.value)">
            <cx-icon [type]="iconType.SEARCH2" [ngClass]="getSearchIconClass(faqOverviewSearchInput.value)"></cx-icon>
        </div>
    </label>
</div>
<div *ngIf="faqItemSuggestions$ | async as faqItems" class="results-dialog">
    <div class="results-wrapper">
        <div class="results" *ngIf="faqItems.length > 0; else noFaqMatchText">
            <div class="faq-item" *ngFor="let faqItem of faqItems; let i=index" [ngClass]="getFaqItemClass(i)">
                <a [routerLink]="{ cxRoute: getForwardRoute(), params: { faqItemCode: faqItem.code } } | cxUrl" 
                [queryParams]="{ search: faqOverviewSearchInput.value }" class="btn-link">
                    {{ faqItem?.title }}
                    <cx-icon [type]="iconType.CARET_RIGHT"></cx-icon>
                </a>
            </div>
        </div>

        <ng-template #noFaqMatchText>
            <div class="no-match-text">
                {{ 'faqItems.overviewSearchBox.help.noMatch' | cxTranslate }}
            </div>
        </ng-template>
    </div>
</div>