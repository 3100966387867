<ng-container *ngIf="goBackTranslationKey$ | async as goBackTranslationKey">
  <p
    (click)="goToPreviosPageOrClose()"
    class="back-nav">
    <cx-icon [type]="iconTypes.ARROW_EXPAND_LEFT"></cx-icon>
    {{ goBackTranslationKey | cxTranslate }}
  </p>
</ng-container>

<ng-container *ngIf="mainImage$ | async as main">
  <app-product-badge *ngIf="productCampaign$ | async as productCampaign" [content]="productCampaign.text"
  [style.backgroundColor]="productCampaign?.backgroundColor"
  [style.color]="productCampaign?.textColor" 
  class="product-images-product-badge"></app-product-badge>
  <ng-container *ngIf="zoomedImageurl; else imageIsMissing">
      <lib-ngx-image-zoom
        [thumbImage]=zoomedImageurl
        [fullImage]=zoomedImageurl
        [magnification]="2"
        [enableScrollZoom]="false"
        [scrollStepSize]="0.1"
        [enableLens]="false"
        [zoomMode]="isTabletMode? 'toggle' :'hover'"
        [lensWidth]="200"
        [lensHeight]="200"
      ></lib-ngx-image-zoom>
  </ng-container>
  <ng-template #imageIsMissing>
    <cx-media [container]="main"></cx-media>
  </ng-template>

</ng-container>

<ng-container *ngIf="thumbs$ | async as thumbs">
  <cx-carousel
    *ngIf="thumbs.length"
    class="thumbs"
    [items]="thumbs"
    itemWidth="120px"
    [hideIndicators]="false"
    [template]="thumb"
  ></cx-carousel>
</ng-container>

<ng-template #thumb let-item="item">
  <cx-media
    [container]="item.container"
    tabindex="0"
    (focus)="openImage(item.container)"
    [class.is-active]="isActive(item.container) | async"
    format="product"
  >
  </cx-media>
</ng-template>
