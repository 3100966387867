import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RoutingService } from '@spartacus/core';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { FaqItem } from '../model/faq-items.model';
import { FaqItemActions } from '../store/actions';
import * as FaqItemFeature from '../store/reducers/faq-item.reducer';
import * as FaqItemSelectors from '../store/selectors/faq-item.selectors';

@Injectable({
  providedIn: 'root',
})
export class FaqItemService {
  private faqItemCode$: Observable<string>;
  private searchTerm$: Observable<string>;

  constructor(
    protected store: Store<FaqItemFeature.FaqItemState>,
    protected routingService: RoutingService
  ) {
    this.faqItemCode$ = this.routingService.getRouterState().pipe(
      map((routingData) => routingData.state.params.faqItemCode),
      distinctUntilChanged()
    );

    this.searchTerm$ = this.routingService.getRouterState().pipe(
      map((routingData) => routingData.state.queryParams.search),
      distinctUntilChanged()
    );
  }

  loadFaqItem(faqItemCode: string, isUserLoggedIn: boolean): void {
    this.store.dispatch(
      FaqItemActions.loadFaqItem({ faqItemCode, isUserLoggedIn })
    );
  }

  isLoading(): Observable<boolean> {
    return this.store.select(FaqItemSelectors.selectLoading);
  }

  getFaqItemResponse(): Observable<FaqItem> {
    return this.store.select(FaqItemSelectors.selectResponse);
  }

  resetFaqItem(): void {
    this.store.dispatch(FaqItemActions.resetFaqItem());
  }

  getFaqItemCode(): Observable<string> {
    return this.faqItemCode$;
  }

  getSearchTerm(): Observable<string> {
    return this.searchTerm$;
  }
}
