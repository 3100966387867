import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActiveCartFacade, Cart } from '@spartacus/cart/base/root';
import { Config, RoutingService } from '@spartacus/core';
import { LaunchDialogService } from '@spartacus/storefront';
import { combineLatest, filter, map, Observable, Subscription } from 'rxjs';
import { UpdateCartService } from '../../../cart-administration/services/update-cart.service';
import { ICON_TYPE } from '../../model/mini-cart-enum.model';
import { CartAndCheckoutLoadingService } from '@shared/services/cart-and-checkout-loading.service';

const CART_ROUTE_NAME = 'cart';

@Component({
  selector: 'app-mini-cart-dialog',
  templateUrl: './mini-cart-dialog.component.html',
})
export class MiniCartDialogComponent implements OnInit, OnDestroy {
  cart$: Observable<Cart>;
  isLoading$: Observable<boolean>;

  readonly iconType = ICON_TYPE;
  readonly numberOfPositionsToDisplay: number =
    this.config.miniCartDialogNumberOfPositionsToDisplay;

  private subscription: Subscription = new Subscription();

  constructor(
    private launchDialogService: LaunchDialogService,
    private routingService: RoutingService,
    private activeCartService: ActiveCartFacade,
    private updateCartService: UpdateCartService,
    private cartAndCheckoutLoadingService: CartAndCheckoutLoadingService,
    @Inject(Config) private config: any
  ) {}

  ngOnInit(): void {
    this.cart$ = this.activeCartService.getActive();
    this.isLoading$ = combineLatest([
      this.activeCartService.isStable(),
      this.updateCartService.isUpdating(),
      this.cartAndCheckoutLoadingService.getCombinedLoading(),
    ]).pipe(
      map(
        ([
          isCartLoaded,
          isCartUpdating,
          isCombinedCartAndCheckoutSpinnerLoading,
        ]) =>
          (!isCartLoaded || isCartUpdating) &&
          !isCombinedCartAndCheckoutSpinnerLoading
      )
    );

    this.subscription.add(
      this.routingService
        .isNavigating()
        .pipe(filter(Boolean))
        .subscribe(() => this.closeModal())
    );
  }

  closeModal(): void {
    this.launchDialogService.closeDialog('closed');
  }

  goToCart(): void {
    this.activeCartService.reloadActiveCart();
    this.routingService.go({
      cxRoute: CART_ROUTE_NAME,
    });
    this.closeModal();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
