export const faqItems = {
  faqItems: {
    overviewSearchBox: {
      placeholder: 'What can we do for you?',
      help: {
        noMatch: 'We could not find any results',
      },
    },

    actions: {
      backToOverview: 'Back to Overview',
    },
  },
};
