import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ErrorType } from '@shared/models/ErrorTypes';
import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { FaqOverviewResponse } from '../../model/faq-items.model';
import { FaqOverviewConnector } from '../../occ/connectors/faq-overview.connector';
import { FaqOverviewActions } from '../actions';

@Injectable()
export class FaqOverviewEffect {
  loadFaqOverview$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FaqOverviewActions.loadFaqOverview),
      concatMap((action) =>
        this.connector.loadOverview(action.isUserLoggedIn, action.searchTerm).pipe(
          map((faqOverviewResponse: FaqOverviewResponse) => {
            return FaqOverviewActions.loadFaqOverviewSuccess({
              response: faqOverviewResponse,
            });
          }),
          catchError((error: ErrorType) => {
            return of(
              FaqOverviewActions.loadFaqOverviewFailure({
                errorResponse: error,
              })
            );
          })
        )
      )
    );
  });

  constructor(
    private actions$: Actions,
    private connector: FaqOverviewConnector
  ) {}
}
