import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { RouterState, RoutingService } from '@spartacus/core';
import { distinctUntilChanged, map, Observable } from 'rxjs';
import { FaqOverviewResponse } from '../model/faq-items.model';
import { FaqOverviewActions } from '../store/actions';
import * as FaqOverviewFeature from '../store/reducers/faq-overview.reducer';
import * as FaqOverviewSelectors from '../store/selectors/faq-overview.selectors';

@Injectable({
  providedIn: 'root',
})
export class FaqOverviewService {
  private readonly searchTerm$: Observable<string>;
  constructor(
    protected routingService: RoutingService,
    protected store: Store<FaqOverviewFeature.FaqOverviewState>
  ) {
    this.searchTerm$ = this.routingService.getRouterState().pipe(
      map((routingData: RouterState) => routingData.state.params.search),
      distinctUntilChanged()
    );
  }

  loadOverview(isUserLoggedIn: boolean, searchTerm: string): void {
    this.store.dispatch(
      FaqOverviewActions.loadFaqOverview({ isUserLoggedIn, searchTerm })
    );
  }

  isLoading(): Observable<boolean> {
    return this.store.select(FaqOverviewSelectors.selectLoading);
  }

  getFaqOverviewResponse(): Observable<FaqOverviewResponse> {
    return this.store.select(FaqOverviewSelectors.selectResponse);
  }

  getSearchTerm(): Observable<string> {
    return this.searchTerm$;
  }

  resetFaqOverview(): void {
    this.store.dispatch(FaqOverviewActions.resetFaqOverview());
  }
}
