import { Component, Input } from '@angular/core';
import { EfaCartItemListComponent } from '@shared/components/efa-cart-item-list/efa-cart-item-list.component';
import { OrderEntry } from '@spartacus/cart/base/root';

@Component({
  selector: 'app-mini-cart-item-list',
  templateUrl: './mini-cart-item-list.component.html',
})
export class MiniCartItemListComponent extends EfaCartItemListComponent {
  @Input() maxItemsToShow: number;
  showAllItems = false;

  toggleShowMore(): void {
    this.showAllItems = !this.showAllItems;
  }

  get lastItemIndexToShow(): number {
    let nomOfItemsInShow: number = 0;
    let lastItemIndexToShow: number;
    this.items.forEach((item: OrderEntry, index: number) => {
      if (!item.subEntry && !item.parent) {
        if (nomOfItemsInShow < this.maxItemsToShow) {
          nomOfItemsInShow = nomOfItemsInShow + 1;
          lastItemIndexToShow = index;
        }
      }
    }) 
    return lastItemIndexToShow;
  }

  get nomOfItemsToShow(): number {
    return this.items.filter((item: OrderEntry) => !item.subEntry && !item.parent).length;
  }
}
