import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { FaqOverviewResponse } from '../model/faq-items.model';
import { FaqOverviewSearchActions } from '../store/actions';
import * as FaqOverviewSearchFeature from '../store/reducers/faq-overview-search.reducer';
import * as FaqOverviewSearchSelectors from '../store/selectors/faq-overview-search.selectors';

@Injectable({
  providedIn: 'root',
})
export class FaqOverviewSearchService {
  constructor(
    protected store: Store<FaqOverviewSearchFeature.FaqOverviewSearchState>
  ) {}

  loadOverviewSearch(isUserLoggedIn: boolean, searchTerm: string): void {
    this.store.dispatch(
      FaqOverviewSearchActions.loadFaqOverviewSearch({
        isUserLoggedIn,
        searchTerm,
      })
    );
  }

  isLoading(): Observable<boolean> {
    return this.store.select(FaqOverviewSearchSelectors.selectLoading);
  }

  getFaqOverviewSearchResponse(): Observable<FaqOverviewResponse> {
    return this.store.select(FaqOverviewSearchSelectors.selectResponse);
  }

  resetFaqOverviewSearch(): void {
    this.store.dispatch(FaqOverviewSearchActions.resetFaqOverviewSearch());
  }
}
