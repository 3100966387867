import { createAction, props } from '@ngrx/store';
import { ErrorType } from '@shared/models/ErrorTypes';
import { FaqOverviewResponse } from '../../model/faq-items.model';

export const loadFaqOverviewSearch = createAction(
  '[FaqItems] Load FAQ Item Overview Search',
  props<{ isUserLoggedIn: boolean; searchTerm: string }>()
);

export const loadFaqOverviewSearchSuccess = createAction(
  '[FaqItems] Load FAQ Item Overview Search Success',
  props<{ response: FaqOverviewResponse }>()
);

export const loadFaqOverviewSearchFailure = createAction(
  '[FaqItems] Load FAQ Item Overview Search Failure',
  props<{ errorResponse: ErrorType }>()
);

export const resetFaqOverviewSearch = createAction(
  '[FaqItems] Reset Faq Item Overview Search'
);
