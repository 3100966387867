<div class="container">
    <div class="row actions">
        <div class="col-12">
            <a (click)="goBack()" class="btn-link btn-back">
                <cx-icon [type]="iconType.CARET_LEFT"></cx-icon>
                {{ 'faqItems.actions.backToOverview' | cxTranslate }}
            </a>
        </div>
    </div>
    <div class="row mt-4 mt-md-5" *ngIf="faqItem$ | async as faqItem">
        <div class="col-12">
            <h3 class="faq-item-title" [innerHTML]="faqItem?.title | textHighlight: (searchTerm$ | async)"></h3>
        </div>
        <div class="col-12">
            <div class="row mt-3 mb-3" *ngFor="let faqContentItem of faqItem?.faqContentItems">
                <div class="col-12" *ngIf="faqContentItem?.text">
                    <span [innerHTML]="faqContentItem.text | cxSupplementHashAnchors | textHighlight: (searchTerm$ | async)">
                    </span>
                </div>
                <div class="col-12 mt-3" *ngIf="faqContentItem?.imageUrl">
                    <img class="u-img-fluid" [attr.src]="faqContentItem.imageUrl" [attr.alt]="''" />
                </div>
            </div>
        </div>
    </div>
</div>
