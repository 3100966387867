import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AuthService, LanguageService, RoutingService } from '@spartacus/core';
import { CmsComponentData } from '@spartacus/storefront';
import {
  combineLatest,
  distinctUntilChanged,
  filter,
  first,
  map,
  Observable,
  startWith,
  Subject,
  Subscription,
  switchMap,
} from 'rxjs';
import { ICON_TYPE } from '../../model/faq-items-icon.model';
import {
  FaqItem,
  FaqOverviewResponse,
  FaqOverviewSearchBoxComponentData,
} from '../../model/faq-items.model';
import { FaqOverviewSearchService } from '../../services/faq-overview-search.service';
import { FaqOverviewService } from '../../services/faq-overview.service';

@Component({
  selector: 'app-faq-overview-search-box',
  templateUrl: './faq-overview-search-box.component.html',
})
export class FaqOverviewSearchBoxComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  faqOverviewResponse$: Observable<FaqOverviewResponse>;
  chosenWord: string;
  faqItemSuggestions$: Observable<FaqItem[]>;
  readonly iconType = ICON_TYPE;

  private maxSuggestions: number;
  private minCharactersBeforeRequest: number;
  private isUserLoggedIn: boolean;
  private readonly input$: Subject<string> = new Subject();
  private readonly subscription: Subscription = new Subscription();
  private readonly faqItemDetailsRoute: string = 'faqItemDetails';
  private readonly faqItemDetailsAnonymousRoute: string =
    'faqItemDetailsAnonymous';
  private readonly faqItemsOverviewSearchRoute: string =
    'faqItemsOverviewSearch';
  private readonly faqItemsOverviewAnonymousSearchRoute: string =
    'faqSearchAnonymous';
  private readonly faqItemsOverviewRoute: string = 'faqItemsOverview';
  private readonly faqItemsOverviewAnonymousRoute: string = 'faqAnonymous';
  @ViewChild('faqOverviewSearchInput')
  private readonly faqOverviewSearchInputElement: ElementRef;

  constructor(
    protected faqOverviewSearchService: FaqOverviewSearchService,
    protected faqOverviewService: FaqOverviewService,
    protected componentData: CmsComponentData<FaqOverviewSearchBoxComponentData>,
    protected authService: AuthService,
    protected languageService: LanguageService,
    protected routingService: RoutingService
  ) {}

  ngOnInit(): void {
    this.faqOverviewSearchService.resetFaqOverviewSearch();

    this.subscription.add(
      this.languageService
        .getActive()
        .pipe(distinctUntilChanged())
        .subscribe(() => this.faqOverviewSearchService.resetFaqOverviewSearch())
    );

    this.subscription.add(
      combineLatest([
        this.authService.isUserLoggedIn().pipe(first()),
        this.input$.pipe(startWith(null)),
      ]).subscribe(([isUserLoggedIn, input]: [boolean, string]) => {
        this.isUserLoggedIn = isUserLoggedIn;

        if (input != null) {
          this.faqOverviewSearchService.loadOverviewSearch(
            isUserLoggedIn,
            input
          );
        }
      })
    );

    this.faqItemSuggestions$ = this.componentData.data$.pipe(
      switchMap((data: FaqOverviewSearchBoxComponentData) => {
        this.maxSuggestions = data.maxSuggestions;
        this.minCharactersBeforeRequest = data.minCharactersBeforeRequest;
        return this.faqOverviewSearchService.getFaqOverviewSearchResponse();
      }),
      map((response: FaqOverviewResponse) =>
        response?.faqRubrics
          ?.flatMap((r) => r.faqItems)
          ?.slice(0, this.maxSuggestions)
      )
    );
  }

  ngAfterViewInit(): void {
    this.subscription.add(
      this.faqOverviewService
        .getSearchTerm()
        .pipe(filter(Boolean), first())
        .subscribe((search: string) => {
          this.faqOverviewSearchInputElement.nativeElement.value = search;
        })
    );
  }

  getFaqItemClass(index: number): string {
    return index === 0 ? 'mb-2' : 'my-2';
  }

  getForwardRoute(): string {
    return this.isUserLoggedIn
      ? this.faqItemDetailsRoute
      : this.faqItemDetailsAnonymousRoute;
  }

  search(searchTerm: string): void {
    if (searchTerm == null || searchTerm?.length === 0) {
      this.faqOverviewSearchService.resetFaqOverviewSearch();
      this.routingService.go({
        cxRoute: this.isUserLoggedIn
          ? this.faqItemsOverviewRoute
          : this.faqItemsOverviewAnonymousRoute,
      });
    } else if (searchTerm?.length >= this.minCharactersBeforeRequest) {
      this.faqOverviewSearchService.resetFaqOverviewSearch();
      this.routingService.go({
        cxRoute: this.isUserLoggedIn
          ? this.faqItemsOverviewSearchRoute
          : this.faqItemsOverviewAnonymousSearchRoute,
        params: {
          search: searchTerm,
        },
      });
    }
  }

  getSearchIconClass(searchInputValue: string): string {
    return searchInputValue != null && searchInputValue !== ''
      ? 'searchbox-filled'
      : 'searchbox-empty';
  }

  change(value: string): void {
    if (value?.length >= this.minCharactersBeforeRequest) {
      this.input$.next(value);
    } else {
      this.faqOverviewSearchService.resetFaqOverviewSearch();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
