<ng-container *ngIf="prices$ | async as prices">
  <cx-carousel
    [ngClass]="(items$ | async).length > 4 ? 'transform-more' :'transform-less'"
    [items]="items$ | async"
    [title]="title$ | async"
    [template]="carouselItem"
    itemWidth="285px"
  >
  </cx-carousel>

  <ng-template #carouselItem let-item="item">
    <div class="carousel-item-container">
      <app-product-badge *ngIf="item.productCampaign" [content]="item.productCampaign?.text"
                         [style.backgroundColor]="item.productCampaign?.backgroundColor"
                         [style.color]="item.productCampaign?.textColor"
                         class="product-carousel-product-badge"></app-product-badge>
      <app-efa-cart-item-add-to-wish-list [product]="item"
                                          [orderInfoRefId]="0"
                                          [IsAddedFromSearchPage]="true">
      </app-efa-cart-item-add-to-wish-list>

      <a tabindex="0" [routerLink]="{ cxRoute: 'product', params: item } | cxUrl"
         [queryParams]="{ source: pdpCallSource }">
        <cx-media class="cx-product-image" [container]="item.images?.PRIMARY"
                  format="product"></cx-media>
        <div class="pl-2">
          <h3 class="product-name">
            {{ item.name }}
          </h3>
          <p class="manufacturerAID">
            {{ item.manufacturerAID ? item.manufacturerAID : item.code.substring(3,
            item.code.length) }}
          </p>
        </div>
      </a>
      <div class="price">
        <span [ngClass]="{'d-none' : getListPriceValue(prices, item.code) === 0}" *ngIf="getCustomerPriceValue(prices, item.code) > 0 && displayCustomerPrices$ | async; else showListPrice">
          <span [ngClass]="{'discounted-price' : getCustomerPriceValue(prices, item.code) < getListPriceValue(prices, item.code)}">
            {{getCustomerPriceFormattedValue(prices, item.code)}}
          </span>
          <span
            *ngIf="getCustomerPriceValue(prices, item.code) < getListPriceValue(prices, item.code)"
            class="crossed-price-wrapper">
            <span
              class="crossed-price-text">{{ 'productPrices.crossedPrice.label' | cxTranslate }}</span>
            <span class="crossed-price">{{getListPriceFormattedValue(prices, item.code)}}</span>
          </span>
        </span>
        <ng-template #showListPrice>
          <span [ngClass]="{'d-none' : getListPriceValue(prices, item.code) === 0}">
            {{getListPriceFormattedValue(prices, item.code)}}
          </span>
        </ng-template>
      </div>
      <div class="carousel-item-container-bottom-content">
        <app-efa-add-to-cart
          [isCalledFromSERP]="true"
          [product]="item"
          [showQuantityHint]="false"
          [priceAvailable]="getListPriceValue(prices, item.code) > 0"
        >

        </app-efa-add-to-cart>
      </div>
    </div>
  </ng-template>
</ng-container>